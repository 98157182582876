import { inject } from '@angular/core';
import { Router, type CanActivateFn } from '@angular/router';
import { LoginService } from '../../auth/services/login.service';
import { AuthStatus } from '../interfaces';

export const isNotAuthenticatedGuard: CanActivateFn = (route, state) => {
  const loginService = inject( LoginService );
  const router = inject( Router );

  if ( loginService.authStatus() === AuthStatus.authenticated ) {
    router.navigateByUrl('/layout/home');
    return false;
  }
  return true;
};
