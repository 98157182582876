import { HttpEvent, HttpResponse, type HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { LoginService } from '@authServices/login.service';
import { tap } from 'rxjs';

export const cachingInterceptor: HttpInterceptorFn = (req, next) => {
  const authToken =  localStorage.getItem('token');
  const modifiedReq = req.clone({
    headers: req.headers.set('Authorization', `Bearer ${authToken}`),
  });

  return next(modifiedReq)
  .pipe(tap((event : HttpEvent<any> ) => {
    if (event instanceof HttpResponse && event.body && !event.body.success) {
      if(event.body.errors)
      {
        if(event.body?.errors[0]?.message == "Unauthorized")
        {
          localStorage.clear();
          // const authService = inject(LoginService);
          // authService.logout();
        }
      }
    }
    return event;
  }));
};
