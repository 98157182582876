import { Routes } from '@angular/router';
import { isNotAuthenticatedGuard } from '@guards/is-not-authenticated.guard';
import { isAuthenticatedGuard } from '@guards/is-authenticated.guard';

export const routes: Routes = [
  {
    path: 'login',
    loadComponent: () => import('./auth/login/login.component'),
    canActivate: [isNotAuthenticatedGuard],
  },
  {
    path: 'layout',
    canActivate: [isAuthenticatedGuard],
    loadComponent: () => import('./layouts/home/home.component'),
    children: [
      {
        path: 'home',
        title: 'Home',
        loadComponent: () => import('./layouts/home/layout/layout.component'),
      },
      {
        path: 'orders',
        title: 'Órdenes',
        loadComponent: () => import('./layouts/orders/orders-list/orders-list.component').then(m => m.OrdersListComponent),
      },
      {
        path: 'addorder',
        title: 'Nueva Orden',
        loadComponent: () => import('./layouts/orders/create-order/create-order.component').then(m => m.CreateOrderComponent),
      },
      {
        path: 'users',
        title: 'Usuarios',
        loadComponent: () => import('./layouts/users/users-list/users-list.component').then(m => m.UsersListComponent),
      },
      {
        path: 'receptions',
        title: 'Recepciones',
        loadComponent: () => import('./layouts/receptions/receptions-list/receptions-list.component').then(m => m.ReceptionsListComponent),
      },
      {
        path: 'packing/:id',
        title: 'Embalaje',
        loadComponent: () => import('./layouts/packing/packing-item/packing-item.component').then(m => m.PackingComponent),
      },
      {
        path: 'packing',
        title: 'Embalaje',
        loadComponent: () => import('./layouts/packing/packing-list/packing-list.component').then(m => m.PackingListComponent),
      },
      {
        path: 'inventory',
        title: 'Inventario',
        children: [
          {
            path: 'empaques',
            title: 'Empaques',
            loadComponent: () => import('./layouts/inventory/in-packing/in-packing.component').then(m => m.PackagingComponent),
          },
          {
            path: 'productos',
            title: 'Productos',
            loadComponent: () => import('./layouts/inventory/products/products.component').then(m => m.ProductsComponent),
          }
        ]
      }


      // {
      //   path:'**', redirectTo: 'home', pathMatch: 'full',
      // }
    ]
  },
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
];
