import { ApplicationConfig, provideZoneChangeDetection, inject } from '@angular/core';
import { provideRouter, withViewTransitions } from '@angular/router';
import { routes } from './app.routes';
import { HttpHeaders, provideHttpClient, withInterceptors } from '@angular/common/http';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { loggingInterceptor } from './core/interceptors/logging.Interceptor';
import { cachingInterceptor } from './core/interceptors/caching.interceptor';
import { provideApollo } from 'apollo-angular';
import { ApolloLink, InMemoryCache } from '@apollo/client/core';
import { provideClientHydration } from '@angular/platform-browser';
import { offsetLimitPagination } from '@apollo/client/utilities';
import { HttpLink } from 'apollo-angular/http';
import { environment } from '@environments/environment.';

export const appConfig: ApplicationConfig = {
  providers: [
      provideHttpClient(withInterceptors([loggingInterceptor, cachingInterceptor])),
      provideAnimationsAsync(),
      provideClientHydration(),
      provideZoneChangeDetection({ eventCoalescing: true }),
      provideRouter(routes, withViewTransitions({ skipInitialTransition: true })),
      provideApollo(() => {
        const httpLink = inject(HttpLink);
        const http = httpLink.create({ uri: environment.baseUrl });

        const middleware = new ApolloLink((operation, forward) => {
          operation.setContext({
            headers: new HttpHeaders().set(
              'Authorization',
              `Bearer ${localStorage.getItem('token')}`,
            ),
          });
          return forward(operation);
        });

        const link = ApolloLink.from([http, middleware]);

        return {
          link,
          cache: new InMemoryCache({
            typePolicies: {
              Query: {
                fields: {
                  feed: offsetLimitPagination()
                }
              }
            }
          }),
          queryDeduplication : false
        };
      })
    ]
};
