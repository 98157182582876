import { Component, computed, effect, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { LoginService } from './auth/services/login.service';
import { AuthStatus } from './auth/interfaces';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent {
  private loginService = inject(LoginService);
  // private router = inject( Router );

  public finishedAuthCheck = computed<boolean>(() => {
    if(this.loginService.authStatus() === AuthStatus.checking)
    {
      return false;
    }
    return true;
  });

  public authStatusChangedEffect = effect(() => {
    switch( this.loginService.authStatus() ) {
      case AuthStatus.checking:
        case AuthStatus.checking:
        return;

      case AuthStatus.authenticated:
        return;

      case AuthStatus.notAuthenticated:
        this.loginService.logout();
        return;
    }
  });
}
